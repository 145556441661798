.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{color: #FFFFFF;}

.progress-circles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* TAB UPLOAD COLUMNS */
.tab-upload-container {
  display: flex;
  justify-content: space-around;
}

.input-column {
  flex-basis: 30%; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* CONTROL GROUP */
.control-text {
  color: #fff; /* or any other color that is visible against your dark background */
}
.controls-container {
  display: flex;
  justify-content: space-around; /* This will space out your controls evenly */
  align-items: center;
  margin-bottom: 20px; /*Adjust the margin as necessary */
}

.controls-container > * {
margin: 0 10px; /* This adds a little space between your controls */
}

/* Style the button as well to match your UI */
.controls-container button {
padding: 10px 20px;
background-color: #444;
color: white;
border: none;
cursor: pointer;
}

/* MATPLOTLIB CHARTS */

/* Override Semantic UI's stackable styles at all widths */
.ui.table.stackable {
  table-layout: auto !important;
}

.ui.table.stackable thead th,
.ui.table.stackable tbody td {
  display: table-cell !important;
  white-space: nowrap !important; /* Prevent text from wrapping */
  overflow: visible !important;
}

/* Override Semantic UI's responsive styles at 768px breakpoint */
@media screen and (max-width: 768px) {
  .ui.table.stackable thead th,
  .ui.table.stackable tbody td {
    display: table-cell !important;
    white-space: nowrap !important; /* Prevent text from wrapping */
    overflow: visible !important;
  }
}

/* Initial styles for larger screens */
.flex-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px; /* Adjust the gap size as needed */
}

.chart-container {
  flex: 1; /* Allow chart to grow and shrink */
}

.table-container {
  flex: 0; /* Allow table to grow and shrink */
  min-width: 200px; /* Prevent the table from getting too narrow */
  max-width: 200px; /* Prevent the table from getting too narrow */
}

/* Media query for when the window gets narrow */
@media screen and (max-width: 768px) {
  .flex-container {
    flex-direction: column; /* Stack the children vertically */
  }

  .chart-container,
  .table-container {
    width: 100%; /* Take the full width of the container */
  }

  .table-container {
    margin: 0 auto; /* Center the table horizontally */
    /* No need to set max-width or min-width here unless you want a specific behavior */
  }
}

/* Ensure the chart scales properly */
.responsive-chart {
width: 100%;
max-width: 100%; /* Limit chart width to prevent overflow */
height: auto; /* Maintain aspect ratio */
max-height: 80vh; /* Set max height to 80% of the viewport height */
}


/* Add other styles as needed */


.panel-header-text {
  color: #FFFFFF !important; /* Set the text color to white for the Panel header */
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .panel-header-text {
  color: #FFFFFF !important; /* Set the text color to white for the Panel header */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
