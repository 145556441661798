/* @import "theme.css"; */
:root {
    --black: #000000;
    --white: #fff;

    --font-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --font-secondary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* or use another font stack if you have another preference */
    --font-mono: Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import "general.css";
@import "components/index.css";
