/* Style for the tab menu item */
.ui.menu .item {
  color: white; /* Change to your desired color */
}

/* Style for the active tab menu item */
.ui.menu .item.active {
  background: black; /* Change to your desired active background color */
  color: white; /* Change to your desired active text color */
}

/* Style for the tab pane content */
.ui.tab.segment.active {
  background: black; /* Change to your desired content background color */
  color: white; /* Change to your desired content text color */
}
