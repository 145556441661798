.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.page-layout__content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;   /* Centers content vertically */
  align-items: center;       /* Centers content horizontally */

  font-size: 2rem;           /* Makes the text larger */
  text-align: center;        /* If there's any child content, it's centered */

  margin-top: 8rem;
  max-width: 120rem;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
    font-size: 1.5rem;       /* Adjusts font size for smaller screens */
  }
}

